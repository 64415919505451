/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum SubStreamingMode {
    AWARD = 'Award',
    EXHIBITION = 'Exhibition',
    FESTIVAL = 'Festival',
    LIBRARY = 'Library',
    MUSEUM = 'Museum',
    OTHER = 'Other',
    THEATRICAL_RELEASE = 'TheatricalRelease',
}
