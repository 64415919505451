import moment from "moment"
import React, { useEffect, useState } from "react"

import {
  LOCAL_STORAGE_EXPIRATION_MINUTES,
  LOCAL_STORAGE_USER,
} from "./constants"
import {
  ContactAyantDroitFrontDto,
  UserAccountDto,
  UserDto,
} from "./domain/api"
import { LocalStorage } from "./helpers/LocalStorage"
import logger from "./helpers/logger"
import { UserProfilFrontDtoExtended, UserStore } from "./models"

export const UserContext = React.createContext<UserStore>({} as UserStore)

interface UserContextProviderProps {
  children: JSX.Element
}

const UserContextProvider = (props: UserContextProviderProps): JSX.Element => {
  // @ts-ignore: Object is possibly 'null'
  const [state, setUser] = React.useState<UserProfilFrontDtoExtended>({
    email: "",
    user: {
      user: {} as UserDto,
    } as UserAccountDto,
    contact: {} as ContactAyantDroitFrontDto,
    currentBeneficiaryId: undefined!,
    references: {
      countries: [],
      nationalities: [],
      repertories: [],
      companyNames: [],
      contractType: [],
      employerName: [],
      countriesEn: [],
      applicationUrlParts: [],
    },
    expiration: moment(),
    // @ts-ignore: Object is possibly 'null'
    redirectDocuSignExpiration: undefined,
    pbeSessions: [],
    // @ts-ignore: Object is possibly 'null'
    locationByRedirect: undefined,
  })
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const dispatch = (obj: UserProfilFrontDtoExtended) => {
    setUser(obj)
    if (obj.user?.userIdDnn)
      LocalStorage.SetItem(LOCAL_STORAGE_USER, JSON.stringify(obj))
    else LocalStorage.RemoveItem(LOCAL_STORAGE_USER)
    logger.setUserContext(
      obj?.user?.user?.userId,
      obj?.user?.user?.email,
      obj?.user?.user?.firstName,
      obj?.user?.user?.lastName,
    )
  }

  useEffect(() => {
    const user: UserProfilFrontDtoExtended | null = JSON.parse(
      LocalStorage.GetItem(LOCAL_STORAGE_USER)!,
    )

    const now = moment()
    if (user && now.isBefore(user.expiration)) {
      const newUser = {
        ...user,
        expiration: now.add(LOCAL_STORAGE_EXPIRATION_MINUTES, "minutes"),
      }
      setUser(newUser)
      LocalStorage.SetItem(LOCAL_STORAGE_USER, JSON.stringify(newUser))
    } else {
      // localStorage.clear()
      LocalStorage.RemoveItem(LOCAL_STORAGE_USER)
    }
    setIsLoaded(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <UserContext.Provider value={{ state, dispatch }}>
      {isLoaded && props.children}
    </UserContext.Provider>
  )
}

export default UserContextProvider
