/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DolSigningEvents } from '../models/DolSigningEvents';
import type { PalFrontDto } from '../models/PalFrontDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SigningService {

    /**
     * @param dolId 
     * @returns DolSigningEvents Success
     * @throws ApiError
     */
    public static cancelDolEnvelopeSigning(
dolId: number,
): CancelablePromise<DolSigningEvents> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/signing/canceldolenvelopeasync/{dolId}',
            path: {
                'dolId': dolId,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param dolId 
     * @returns DolSigningEvents Success
     * @throws ApiError
     */
    public static checkDolSigningStatusSigning(
dolId: number,
): CancelablePromise<DolSigningEvents> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/signing/checkdolsigningstatus/{dolId}',
            path: {
                'dolId': dolId,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static sendDocumentForSignatureSigning(
requestBody?: PalFrontDto,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/signing/senddocumentforsignature',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param dolId 
     * @returns string Success
     * @throws ApiError
     */
    public static sendDolDocumentForSignatureSigning(
dolId?: number,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/signing/senddoldocumentforsignature',
            query: {
                'dolId': dolId,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

}
